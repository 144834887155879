<template>
  <div class="u__background--white">
    <Content>
      <FlexContainer>
        <FlexItem padding="normal" :grow="true">
          <h2>Terms of Service</h2>
          <p />
        </FlexItem>
      </FlexContainer>
    </Content>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
ol {
  list-style-type: disc;
  margin-left: $margin;

  li {
    margin-bottom: $margin;
  }
}
</style>
